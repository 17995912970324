const mountBodyDirective = {
  install(app) {
    app.directive('mount-body', {
      mounted: function (el) {
        const container = document.body

        if (container) {
          container.appendChild(el) // bottom of target
        } else {
          console.warn('body element not found')
        }
      },
      unmounted: function (el) {
        if (el.parentNode) {
          el.parentNode.removeChild(el)
        }
      }
    })
  }
}

export default mountBodyDirective
